<template>
  <button
    @click="$emit('show-documentos', true)"
    class="
      bg-white
      hover:bg-gray-100
      text-gray-800
      py-2
      px-3
      border border-gray-400
      rounded
      btn-custom-right
    "
    :class="clases"
    v-html="html"
  >
    <!-- <i class="fa fa-bars"></i> -->
  </button>
</template>

<script>
export default {
  name: "Button-Documentos",
  props: ["html", "clases"],
};
</script>

<style></style>