<template>
  <div class="relative rounded-xl overflow-auto p-8">
    <div class="flex items-center justify-center">
      <span class="relative inline-flex">
        <button
          @click="$emit('init-camara')"
          type="button"
          class="
            inline-flex
            items-center
            text-white
            py-2
            px-4
            rounded
            btn-salmon
            transition
            ease-in-out
            duration-150
          "
          :class="{ 'cursor-not-allowed': loadBtnCamera }"
          :disabled="loadBtnCamera"
        >
          <svg
            v-if="loadBtnCamera"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <i v-else class="icon-camera-3 pr-2"></i> {{ label }}
        </button>
        <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
          <span
            class="
              animate-ping
              absolute
              inline-flex
              h-full
              w-full
              rounded-full
              bg-salmon2-400
              opacity-75
            "
          ></span>
          <span
            class="relative inline-flex rounded-full h-3 w-3 bg-salmon2-500"
          ></span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonCamara",
  props: {
    label: String,
    loadBtnCamera: Boolean,
  },
};
</script>

<style scoped>
</style>