<template>
  <div
    class="
      flex
      items-center
      justify-center
      w-screen
      h-screen
    "
  >
    <div class="px-5 py-20 rounded-md">
      <div class="flex flex-col items-center">
        <div class="pb-5">
          <img :src="logo" alt="" class="w-80"/>
        </div>
        <h6
          class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl"
        >
          <span class="text-red-500">Token</span> no existe o ha expirado
        </h6>

        <p class="mb-8 text-center text-gray-500 md:text-lg">
          El token que ha introducido no existe o ha expirado.
        </p>

        <router-link
          class="px-6 py-2 text-sm text-blue-800 btn-salmon"
          :to="{
            path: '/verify/6AB3XQK0PHI5FLHi19GOCwROqxXIzUyIyJDBGYL5Spf3AZsODpy2U!!xfZ6btLs19mm!!StPUD39dhsxIo37spbQ7O2R5i3ArY2QuVmEZF4n0=',
          }"
        >
          TOKEN DEMO</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      errored: false,
      logo: require("../../../assets/img/logo.png"),
    };
  },
};
</script>

