<template>
  <div class="flex items-center justify-center h-screen-nav ">
  
    <div class="text-white rounded-lg p-10 text-center">
        <h1
        class="
          text-3xl
          font-normal
          leading-normal
          mt-0
          mb-2
          tc-salmon
          font-bold
        "
      >
        Elegir Documento
      </h1>
      <div v-bind:key="documento.cod" v-for="documento in documentos">
        <div
          @click="selectDocumento(documento)"
          class="
            max-w-lg
            shadow-lg
            rounded
            overflow-hidden
            mb-4
            sm:m-4 sm:flex
            cursor-pointer
            center-documentos
          "
        >
          <img
            :src="documento.img"
            class="loadImage-SD"
            style="display: none"
            v-if="!loadImagen"
          />
          <div
            class="
              h-38 h-custom
              sm:h-auto sm:w-38
              md:w-80
              flex-none
              bg-contain bg-no-repeat bg-center
              rounded rounded-t
              sm:rounded sm:rounded-l
              text-center
              overflow-hidden
            "
            :style="
              loadImagen ? `background-image: url('${documento.img}')` : ''
            "
          >
            <vue-skeleton-loader
              v-if="!loadImagen"
              type="rect"
              :width="141"
              :height="90"
              animation="wave"
              style="
                z-index: -1;
                margin-bottom: 4px;
                margin-left: 6px;
                margin-top: 6px;
                margin-right: 6px;
              "
              class="w-full-custom"
            />
          </div>

          <div class="py-2 md:py-4">
            <button
              class="
                py-3
                px-6
                text-white
                rounded-full
                mt-1
                mb-2
                tc-gris-oscuro
                label-custom
                md:text-base
              "
            >
              {{ documento.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeleccioneDocumento",
  props: ["swapComponent", "tipoDocumento"],
  data() {
    return {
      query: "",
      loadImagen: false,
      documentos: [
        {
          label: "DNI Azul",
          img: require("./../../../assets/img/per1.png"),
          cod: "per1",
        },
        {
          label: "DNI Electrónico",
          img: require("./../../../assets/img/per2.png"),
          cod: "per2",
        },
        {
          label: "DNI Electrónico 2",
          img: require("./../../../assets/img/per5.png"),
          cod: "per5",
        },
      ],
    };
  },
  methods: {
    selectDocumento: function (v) {
      this.$parent.tipoDocumento = v.cod;
      this.swapComponent("Camara");
    },
  },
  mounted() {
    const cover = document.querySelector(".loadImage-SD");
    cover.onload = () => {
      this.loadImagen = true;
    };
  },
};
</script>

<style scoped>
#search-container {
  width: 600px;
  margin: 0 auto;
}
input {
  padding: 10px;
  width: 100%;
  outline: none;
}
@media (min-width: 768px) {
  .md\:w-80 {
    width: 9rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .h-custom {
    height: 6rem;
  }

  .label-custom {
    padding: 0;
    margin: 0;
  }
  .w-full-custom {
    width: 138px !important;
    height: 142px !important;
    margin: 0;
  }
  .center-documentos{
    width: 150px;
    margin: 0 auto 1rem
    
  }
}
</style>