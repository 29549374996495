<template>
  <div class="flex items-center justify-center h-screen-nav">
    <div class="text-white rounded-lg p-10">
      <h1
        class="
          text-3xl
          font-normal
          leading-normal
          mt-0
          mb-2
          tc-salmon
          font-bold
        "
        :class="{ 'skeleton-text skeleton-effect-wave': !loadComponent }"
      >
        
        {{contenido.titulo}}
      </h1>
      <div class="flex flex-col divide-y">
        <div
          class="py-5 tc-gris-ladrillo tc-gris-oscuro"
          :class="{ 'skeleton-text skeleton-effect-wave': !loadComponent }"
        >
         {{contenido.p1}}
        </div>
        <div
          class="py-5 tc-gris-ladrillo tc-gris-oscuro"
          :class="{ 'skeleton-text skeleton-effect-wave': !loadComponent }"
        >
           {{contenido.p2}}
        </div>
        <div
          class="py-5 tc-gris-ladrillo tc-gris-oscuro"
          :class="{ 'skeleton-text skeleton-effect-wave': !loadComponent }"
        >
           {{contenido.p3}}
        </div>
      </div>
      <ButtonNext
        :label="'Continuar'"
        v-if="loadComponent"
        :swap-component="swapComponent"
        :nextNameComponent ="'Recomendaciones'"
      />
    </div>
  </div>
</template>


<script>
import ButtonNext from "./ButtonNext.vue";

const loadComponent = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 4000);
  });
};

export default {
  name: "Pasosdelproceso",
  props: ["swapComponent"],
  data() {
    return {
      toast: () => {},
      query: "",
      loadComponent: false,
      contenido:{
        titulo: "Pasos del proceso",
        p1: "1. Foto del rostro.",
        p2: "2. Foto del DNI por ambos lados.",
        p3: "3. Firma de documentos.",
      }
    };
  },
  components: {
    ButtonNext,
  },
  methods: {},
  created() {
  },
  async mounted() {
    loadComponent().then(() => {
      this.loadComponent = true;
    });
  },
};
</script>


<style scoped>
#search-container {
  width: 600px;
  margin: 0 auto;
}
input {
  padding: 10px;
  width: 100%;
  outline: none;
}
</style>