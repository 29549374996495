<template>
  <div>
   Recover
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      logo: true
    };
  },
};
</script>

<style>
</style>
