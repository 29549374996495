<template>
  <div>
    <v-card>
      <SlideToggle :open="open">
        <v-card-title v-if="toggleFilterState">
          <!-- <download-excel
        class="btn btn-default"
        :data="json_data"
        :fields="json_fields"
        type="csv"
        name="filename.xls"
      >
      </download-excel> -->

          <v-row>
            <v-col cols="12" sm="12" md="12"> Filtros&nbsp; </v-col>

            <v-col cols="12" sm="9" md="4">
              <vc-date-picker
                @input="onDateRangeChangeReporte"
                v-model="rangeReporte"
                :locale="{
                  id: 'es',
                  firstDayOfWeek: 2,
                  masks: { weekdays: 'WWW' },
                }"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    label="Desde - Hasta"
                    prepend-icon="mdi-calendar"
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on="inputEvents.start"
                    readonly
                    outlined
                    dense
                  >
                  </v-text-field>
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <v-btn color="primary" block @click="filtrarTabla">
                <v-icon> mdi-magnify </v-icon> Buscar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
      </SlideToggle>
      <v-divider v-if="!loadTable"></v-divider>
      <v-card-title v-if="tDataBody.length > 0">
        <v-row>
          <!-- <v-col>
            <v-item-group class="v-btn-toggle">
              <v-btn >
                <v-icon>add</v-icon>asas
              </v-btn>
              <v-btn >
                <v-icon>remove</v-icon>asas
              </v-btn>
            </v-item-group>
          </v-col> -->
          <v-col cols="12" md="3" sm="6">
            <v-select
              v-model="selectedHeaders"
              :items="headers"
              label=""
              multiple
              outlined
              return-object
              solo
              dense
              :elevation="false"
              class="select-box-shadow-none"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 1" style="background: transparent">
                  <v-icon left> mdi-view-column </v-icon>
                  Columnas
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="5" sm="6">
            <v-btn-toggle mandatory>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    class="pt-5 pb-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleFilter"
                  >
                    <v-icon left >  {{open?"mdi-eye-off":"mdi-eye "}}</v-icon>
                    <span class="hidden-sm-and-down">Filtros</span>
                  </v-btn>
                </template>
                <span>{{open?"Ocultar":"Mostrar"}} Filtro</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    class="pt-5 pb-5"
                    @click="ActionTableRefresh"
                    :loading="refreshButtonState"
                    :disabled="refreshButtonState"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-refresh </v-icon>
                    <span class="hidden-sm-and-down">Refrescar</span>
                  </v-btn>
                </template>
                <span>Refrescar tabla</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    value="right"
                    small
                    class="pt-5 pb-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="ActionTableExportToExcel"
                  >
                    <v-icon left> mdi-file-export </v-icon>
                    <span class="hidden-sm-and-down">Excel</span>
                  </v-btn>
                </template>
                <span>Exportar a Excel</span>
              </v-tooltip>
              <!--    <v-btn value="justify" small class="pt-5 pb-5">
              <span class="hidden-sm-and-down">Justify</span>

              <v-icon right> mdi-format-align-justify </v-icon>
            </v-btn> -->
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <br v-if="loadTable" />
      <v-data-table
        :headers="showHeaders"
        :items="tDataBody"
        :search="search"
        :loading="loadTable"
        loading-text="Cargando... Porfavor espere"
        fixed-header
        height="60vh"
        @contextmenu:row="rightClickHandler"
      >
        <template v-slot:item.state="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="getColorState(item.state)"
                v-bind="attrs"
                v-on="on"
                text-color="white"
                small
              >
                {{ item.state | textEstado }}
              </v-chip>
            </template>
            <span> {{ item.state | textEstadoDesc }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.estado_final="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="getColorStateFinFirma(item.estado_final)"
                v-bind="attrs"
                v-on="on"
                text-color="white"
                small
              >
                {{ item.estado_final | textEstadoFinFirma }}
              </v-chip>
            </template>
            <span> {{ item.estado_final | textEstadoFinFirmaDesc }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.visitado="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="item.visitado > 0 ? 'green' : 'gray'"
                v-show="item"
                v-bind="attrs"
                v-on="on"
                text-color="white"
                small
              >
                <v-icon v-if="item.visitado > 0">mdi-eye-check-outline</v-icon>
                <v-icon v-else>mdi-eye-settings-outline</v-icon>
              </v-chip>
            </template>
            <span> Se visitó el enlace {{ item.visitado }} veces</span>
          </v-tooltip>
        </template>
        <template v-slot:item.incidencias="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="item.incidencias > 0 ? 'red' : 'blue'"
                v-show="item"
                v-bind="attrs"
                v-on="on"
                text-color="white"
                small
              >
                <v-icon v-if="item.incidencias > 0"
                  >mdi-emoticon-sad-outline
                </v-icon>
                <v-icon v-else>mdi-emoticon-outline</v-icon>({{
                  item.incidencias
                }})
              </v-chip>
            </template>
            <span> Ocurrieron {{ item.incidencias }} incidencias</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      transition="scroll-y-transition"
    >
      <v-card class="mx-auto" max-width="600" tile>
        <v-list dense>
          <v-subheader>Menu</v-subheader>
          <v-list-item-group color="primary">
            <template v-for="(item, i) in items">
              <v-list-item
                v-if="!item.divider"
                :key="i"
                @click="menuEventClick(item.title, item.action)"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-else :key="i"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <vmodal
      :title="modalSetting.title"
      :openmodal="modalSetting.show"
      :json="modalSetting.data"
      v-on:closeEvent="changeOpenModal"
    ></vmodal>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import vmodal from "./vmodal.vue";
import { VueSlideToggle } from "vue-slide-toggle";

export default {
  components: {
    vmodal,
    SlideToggle: VueSlideToggle,
  },
  data() {
    return {
      open: true,
      selectedHeaders: [],
      overlay: false,
      memoryDataLogBiometry: [],
      memoryDataSendFinFirma: [],
      memoryResponseFinFirma: [],
      modalSetting: {
        title: "",
        show: false,
        data: [],
      },
      fromDateMenu: false,
      fromDateVal: null,
      minDate: "2020-01-05",
      maxDate: "2019-08-30",
      selectedDate: null,
      refreshButtonState: false,
      loadTable: false,
      search: "",
      headers: [],
      headersMap: [
        {
          text: "ID",
          align: "start",
          value: "id",
          width: "70",
        },
        { text: "ID Transacción", value: "idtransaccion", width: "180" },
        { text: "Nombre", value: "nombre", width: "100" },
        { text: "Otros Nombres", value: "nombre_otros", width: "150" },
        { text: "Paterno", value: "apellido_pat", width: "100" },
        { text: "Materno", value: "apellido_mat", width: "100" },
        { text: "DNI", value: "numero_documento", width: "100" },
        { text: "Fecha", value: "datireg", width: "200" },
        { text: "Visto", value: "visitado", width: "150" },
        { text: "Incidencias", value: "incidencias", width: "150" },
        { text: "Estado Cliente", value: "state", width: "130" },
        { text: "Estado Fin Firma", value: "estado_final", width: "150" },
      ],
      tDataHeader: [],
      tDataBody: [],

      showMenu: false,
      x: 0,
      y: 0,
      selectedItem: 0,
      items: [
        {
          title: "Copiar Token Web Completo",
          icon: "mdi-content-copy",
          action: "copyTokenPage2",
        },
        {
          title: "Copiar Token Web",
          icon: "mdi-content-copy",
          action: "copyTokenPage",
        },
        { divider: true },
        {
          title: "Detalles",
          icon: "mdi-code-json",
          action: "logDataFinFirma",
        },
        {
          title: "Body para consumir Fin Firma",
          icon: "mdi-code-json",
          action: "logDataFinFirma",
        },
        { divider: true },
        {
          title: "Log Incidencias en Biometría",
          icon: "mdi-code-json",
          action: "logBiometryc",
        },
        {
          title: "Log Biometría",
          icon: "mdi-code-json",
          action: "logBiometryc",
        },
        {
          title: "Log Fin Firma",
          icon: "mdi-code-json",
          action: "logResponseFinFirma",
        },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      json_fields: {
        "Complete name": "name",
        City: "city",
        Telephone: "phone.mobile",
        "Telephone 2": {
          field: "phone.landline",
          callback: (value) => {
            return `Landline Phone - ${value}`;
          },
        },
      },
      json_data: [
        {
          name: "Tony Peña",
          city: "New York",
          country: "United States",
          birthdate: "1978-03-15",
          phone: {
            mobile: "1-541-754-3010",
            landline: "(541) 754-3010",
          },
        },
        {
          name: "Thessaloniki",
          city: "Athens",
          country: "Greece",
          birthdate: "1987-11-23",
          phone: {
            mobile: "+1 855 275 5071",
            landline: "(2741) 2621-244",
          },
        },
      ],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      rangeReporte: {
        start: new Date(),
        end: new Date(),
      },

      loadAjax: false,
      toggleFilterState: true,
      selectIdTransaccion: null,
      selectIdTransaccionDataRow: {},
    };
  },
  async created() {},
  methods: {
    changeOpenModal() {
      this.modalSetting.title = "";
      this.modalSetting.data = [];
      this.modalSetting.show = false;
    },
    async consultar(id, url) {
      const response = await axios
        .get(url + "/" + id)
        .catch((error) => error.response);
      if (response.status != 200) {
        if (response.status == 500)
          return this.stopSecuence("Error en el servidor");
        else if (response.status == 404)
          return this.stopSecuence(
            !response.data.message
              ? "No hay conexión con el servicio"
              : response.data.message
          );
        else return this.stopSecuence(response.data.message);
      }

      return response.data;
    },
    async filtrarTabla() {
      this.toggleFilter();
      this.loadTable = true;

      this.refreshButtonState = this.loadTable = true;
      this.tDataHeader =this.headers = this.tDataBody = [];
      this.tDataBody = await this.getDataTable();
      if (this.tDataBody.length > 0) {
        // this.tDataHeader = this.headersMap;
        this.headers = Object.values(this.headersMap);
        this.selectedHeaders = this.headers;
        this.loadTable = this.refreshButtonState = false;
      } else {
        this.headers = [];
        this.loadTable = this.refreshButtonState = false;
        this.toggleFilterState = true;
        this.toggleFilter();
      }
    },
    toggleFilter() {
      this.open = !this.open;
      // this.toggleFilterState = !this.toggleFilterState;
    },
    addDate() {
      this.dates.push({
        date: new Date(),
      });
      this.$nextTick(() => {
        const btn = this.$refs.button[this.$refs.button.length - 1];
        btn.click();
      });
    },
    removeDate(date, hide) {
      this.dates = this.dates.filter((d) => d !== date);
      hide();
    },
    dateSelected(e, date, toggle) {
      this.selected = date;
      toggle({ ref: e.target });
    },
    onDateRangeChangeReporte() {
      this.loadAjax = true;
      /* console.log("onDateRangeChange: Actualizando datos fecha")
            this.filtros.fstart = moment(this.rangeReporte.start).format('YYYY-MM-DD');
            this.filtros.fend = moment(this.rangeReporte.end).format('YYYY-MM-DD');
           
            this.graficoDinamic(); */
    },
    export2Excel() {
      console.log("log excel");
      /* require.ensure([], () => {
        const { export_json_to_excel } = require('../excel/Export2Excel');
        const tHeader = ['Serial number', 'Nickname?', 'Full name'];  // Set the title of the first row of Excel table
        const filterVal = ['index', 'nickName', 'name'];  // index, nickName and name are properties of objects in tableData
        const list = this.tableData;  //Save tableData in data to list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, 'Export filename');  //Export Excel file name
      }) */
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    async ActionTableRefresh() {
      this.refreshButtonState = this.loadTable = true;
      this.headers = this.tDataBody = [];
      this.tDataBody = await this.getDataTable();
      this.headers = this.headersMap;
      this.refreshButtonState = this.loadTable = false;
    },
    ActionTableExportToExcel() {
      console.log("exportando");
    },
    getColorState(value) {
      if (value == "C") return "green";
      else if (value == "V") return "orange";
      else if (value == "I") return "light blue";
      else return "red";
    },
    getColorStateFinFirma(value) {
      if (value == "C") return "green";
      else if (value == "E") return "light blue";
      else if (value == "P") return "orange";
      else if (value == "F") return "red";
      else return "red";
    },
    async getDataTable() {
      const article = {
        start: this.moment(this.rangeReporte.start).format("YYYY-MM-DD"),
        end: this.moment(this.rangeReporte.end).format("YYYY-MM-DD"),
      };
      const response = await axios
        .post(this.$apis.listado, article)
        .catch((error) => error.response);
      if (response.status != 200) {
        if (response.status == 500)
          return this.stopSecuence("Error en el servidor");
        else if (response.status == 404)
          return this.stopSecuence(
            !response.data.message
              ? "No hay conexión con el servicio"
              : response.data.message
          );
        else return this.stopSecuence(response.data.message);
      }
      if (response.data.error) return this.stopSecuence(response.data.message);
      return response.data.data;
    },
    rightClickHandler(e, item) {
      e.preventDefault();

      this.selectIdTransaccion = item.item.id;
      this.selectTransaccionDataRow = item.item;
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async menuEventClick(t, e) {
      this.modalSetting.title = t;
      let id = this.selectIdTransaccion;
      if (e == "copyTokenPage") {
        const tokenpage = this.selectTransaccionDataRow.tokenpage;
        this.copy(tokenpage);
        this.toastAlert("success", "Token copiado!", 2000, "bottom-end");
      }
      if (e == "copyTokenPage2") {
        const tokenpage = this.selectTransaccionDataRow.tokenpage;
        this.copy(
          `${window.location.protocol}//${window.location.host}${
            process.env.NODE_ENV === "production" ? "/credicorpvue/" : "/"
          }verify/${tokenpage}`
        );
        this.toastAlert("success", "Token copiado!", 2000, "bottom-end");
      }
      if (e == "logBiometryc") {
        this.overlay = true;
        let data = await this.consultar(id, this.$apis.consola.logBiometric);

        let index = await this.memoryDataLogBiometry.findIndex(
          (a) => a.tab == id
        );
        if (index != -1) {
          await this.memoryDataLogBiometry.splice(index, 1);
        }

        let structuraData = { tab: id, content: data };
        this.memoryDataLogBiometry = [
          structuraData,
          ...this.memoryDataLogBiometry,
        ];
        this.overlay = false;
        this.modalSetting.show = true;
        this.modalSetting.data = this.memoryDataLogBiometry;
      }
      if (e == "logDataFinFirma") {
        this.overlay = true;
        let data = await this.consultar(
          id,
          this.$apis.consola.showDataFinFirma
        );

         if (data.error){
           this.overlay = false;
           return this.toastAlert(
              "info",
              "Sin datos por mostrar",
              3000,
              "bottom-end"
            );
         }
            

        let index = await this.memoryDataSendFinFirma.findIndex(
          (a) => a.tab == id
        );
        if (index != -1) {
          await this.memoryDataSendFinFirma.splice(index, 1);
        }

        let structuraData = { tab: id, content: data };
        this.memoryDataSendFinFirma = [
          structuraData,
          ...this.memoryDataSendFinFirma,
        ];
        this.overlay = false;
        this.modalSetting.show = true;
        this.modalSetting.data = this.memoryDataSendFinFirma;
      }

      if (e == "logResponseFinFirma") {
        // this.overlay = true;

        // this.overlay = false;
        let historico = this.selectTransaccionDataRow.historico;
        if (historico === null)
          return this.toastAlert(
            "info",
            "Sin datos por mostrar",
            3000,
            "bottom-end"
          );

        if (this.isObject(historico)) {
          historico = JSON.parse(historico);
        }

        let index = await this.memoryResponseFinFirma.findIndex(
          (a) => a.tab == id
        );
        if (index != -1) {
          await this.memoryResponseFinFirma.splice(index, 1);
        }

        let structuraData = { tab: id, content: historico };
        this.memoryResponseFinFirma = [
          structuraData,
          ...this.memoryResponseFinFirma,
        ];
        this.modalSetting.show = true;
        this.modalSetting.data = this.memoryResponseFinFirma;
      }
    },
    copy(text) {
      const elem = document.createElement("textarea");
      elem.value = text;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);
    },
    isObject(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  filters: {
    textEstado: function (value) {
      let texto = "";
      if (value == "C") texto = "Completado";
      else if (value == "V") texto = "Verificado";
      else if (value == "I") texto = "Pendiente";
      else texto = "Error";
      return texto;
    },
    textEstadoDesc: function (value) {
      let texto = "";
      if (value == "C")
        texto = "El cliente a completado todo el proceso de firma";
      else if (value == "V") texto = "El cliente fue verificado exitosamente";
      else if (value == "I")
        texto = "Iniciado por credicorp y pendiente por el cliente";
      else texto = "Aún no definido";
      return texto;
    },
    textEstadoFinFirma: function (value) {
      let texto = "";
      if (value == "C") texto = "Completado";
      else if (value == "E") texto = "En Espera";
      else if (value == "P") texto = "Procesando";
      else if (value == "F") texto = "Fallido";
      else texto = "Error no identificado";
      return texto;
    },
    textEstadoFinFirmaDesc: function (value) {
      let texto = "";
      if (value == "C") texto = "Envío de documentos completado correctamente";
      else if (value == "E")
        texto =
          "Esperando que el cliente complete su proceso de biometría y firma";
      else if (value == "P")
        texto = "Procesando para ser enviado al api fin firm (bachero)";
      else if (value == "F")
        texto = "Envío de documentos completado con errores";
      else texto = "Error no identificado";
      return texto;
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },
};
</script>

<style >
/* .portrait.v-card {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
} */
.select-box-shadow-none .v-input__slot {
  box-shadow: none !important;
}
</style>