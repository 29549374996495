<template>
  <div class="flex items-center justify-center w-screen h-screen ">
    <img :src="logo" draggable=false>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      logo: require("./../../assets/img/logo.png"),
      logoPrealoader: require("./../../assets/img/logo-blanco-preloader.svg"),
    };
  },
};
</script>

<style>
</style>
