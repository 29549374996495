import { en } from 'vuetify/lib/locale'

export default {
    ...en,
    auth: {
        'sign-in': {
            'create-account': 'Crear cuenta',
            'forgot-email': 'Forgot email?',
            label: 'Ingresa tu usuario',
            'learn-more': 'Learn more',
            next: 'Siguiente',
            private: 'Not your computer? Use a Private Window to sign in.',
            subtitle: 'Usa tu cuenta de Credicorp',
            title: 'Autenticación'
        },
        'sign-in-password': {
            'enter-password': 'Ingresa tu contraseña',
            'forgot-password': 'Forgot password?',
            next: 'Ingresar',
            title: 'Welcome'
        },
        'sign-up': {
            create: 'Create your Vuetify Account',
            email: 'Your email address',
            'email-hint': "You'll need to confirm that this email belongs to you.",
            firstname: 'First name',
            instead: 'Sign in instead',
            lastname: 'Last name',
            next: 'Next',
            password: 'Password',
            'password-props': 'Use 8 or more characters with a mix of letters, numbers & symbols',
            title: 'Vuetify'
        }
    },
    login: {
        footer: {
            help: 'Help',
            language: 'English (United States)',
            privacy: 'Privacy',
            terms: 'Terms'
        }
    }
}