<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};


</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        style="width: 360px"
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
      
        <slot name="loading"></slot>
      
        <header class="modal-header" id="modalTitle">
          <slot name="header">  </slot>
        </header>

        <section class="modal-body" id="modalDescription">
          <slot name="body">  </slot>
        </section>

        <footer class="modal-footer">
        
          <slot name="footer">
            
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.modal-backdrop {
  z-index: 9;
}
.modal{
  box-shadow: none !important;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color:  rgb(255 255 255 / 95%);
  /* background-color: rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
}

.modal-header {
  position: relative;
  /* border-bottom: 1px solid #eeeeee; */
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  /* border-top: 1px solid #eeeeee; */
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.1s ease;
}
</style>