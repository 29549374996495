<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  data() {
    return {
      logo: require("./assets/img/logo.png"),
      logoPrealoader: require("./assets/img/logo-blanco-preloader.svg"),
      currentComponent: "Pasosdelproceso",
      info: null,
      loading: true,
      errored: false,
      direction: "slide-bottom",
      directionOld: 0,
    };
  },
  components: {},
  mounted() {
    let themejs = document.createElement("script");
    /* themejs.setAttribute(
      "src",
      "https://sandbox-web-plugins.s3.amazonaws.com/autocapture/autocapture.js"
    );
    document.head.appendChild(themejs);
    themejs = document.createElement("script");
    themejs.setAttribute(
      "src",
      "https://sandbox-web-plugins.s3.amazonaws.com/liveness/js/liveness.js"
    ); */
    themejs.setAttribute(
      "src",
      "https://prod-web-plugins.s3.amazonaws.com/autocapture/autocapture.js"
    );
    document.head.appendChild(themejs);
    themejs = document.createElement("script");
    themejs.setAttribute(
      "src",
      "https://prod-web-plugins.s3.amazonaws.com/liveness/js/liveness.js"
    );
    document.head.appendChild(themejs);
    themejs = document.createElement("script");
    themejs.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/ua-parser-js@0.7.18/src/ua-parser.min.js"
    );
    document.head.appendChild(themejs);
    themejs = document.createElement("script");
    themejs.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/bodymovin/4.13.0/bodymovin.min.js"
    );
    document.head.appendChild(themejs);
    themejs = document.createElement("script");
    themejs.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/webrtc-adapter/6.2.1/adapter.min.js"
    );
    document.head.appendChild(themejs);
    themejs = document.createElement("script");
    themejs.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.1.1/socket.io.js"
    );
    document.head.appendChild(themejs);
    themejs = document.createElement("script");
    themejs.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/siema@1.5.1/dist/siema.min.js"
    );
    document.head.appendChild(themejs);
  },
};
</script>

<style>
</style>
