<template>
  <div
  class="
    flex
    items-center
    justify-center
    w-screen
    h-screen
    from-indigo-600
  "
>
  <div class="px-40 py-20  rounded-md ">
    <div class="flex flex-col items-center">
      <h1 class="font-bold text-7xl txt-celeste ">404</h1>

      <h6 class="mb-2 text-2xl font-bold text-center txt-gris-oscuro md:text-3xl">
       No se encontró la   <span class=" txt-salmon">página</span>
      </h6>

      <p class="mb-8 text-center txt-gris-claro md:text-lg">
       Lo sentimos, la página que busca ya no existe.
      </p>
        <a class="h-10 px-5 pt-2 text-blue-100 transition-colors duration-150 bg-celest-custom rounded-lg focus:shadow-outline hover:bg-blue-700" href="https://www.credicorpcapital.com/">Regresar a la web</a>
    </div>
  </div>
</div>
</template>

<script>


export default {
  name: "Home",
  data() {
    return {
      errored: false,
    };
  },
 
};
</script>

