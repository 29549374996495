<template>
  <div class="container">
    <div class="squares">
      <div class="square" v-for="i in 4" :key="i"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Squares',
};
</script>

<style  scoped>

</style>