var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('SlideToggle',{attrs:{"open":_vm.open}},[(_vm.toggleFilterState)?_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_vm._v(" Filtros  ")]),_c('v-col',{attrs:{"cols":"12","sm":"9","md":"4"}},[_c('vc-date-picker',{attrs:{"locale":{
                id: 'es',
                firstDayOfWeek: 2,
                masks: { weekdays: 'WWW' },
              },"is-range":""},on:{"input":_vm.onDateRangeChangeReporte},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var inputValue = ref.inputValue;
              var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({attrs:{"label":"Desde - Hasta","prepend-icon":"mdi-calendar","value":inputValue.start + ' - ' + inputValue.end,"readonly":"","outlined":"","dense":""}},inputEvents.start))]}}],null,false,1963625492),model:{value:(_vm.rangeReporte),callback:function ($$v) {_vm.rangeReporte=$$v},expression:"rangeReporte"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.filtrarTabla}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_vm._v(" Buscar")],1)],1)],1)],1):_vm._e()],1),(!_vm.loadTable)?_c('v-divider'):_vm._e(),(_vm.tDataBody.length > 0)?_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-select',{staticClass:"select-box-shadow-none",attrs:{"items":_vm.headers,"label":"","multiple":"","outlined":"","return-object":"","solo":"","dense":"","elevation":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index < 1)?_c('v-chip',{staticStyle:{"background":"transparent"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-view-column ")]),_vm._v(" Columnas ")],1):_vm._e()]}}],null,false,1297914879),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"6"}},[_c('v-btn-toggle',{attrs:{"mandatory":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pt-5 pb-5",attrs:{"small":""},on:{"click":_vm.toggleFilter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.open?"mdi-eye-off":"mdi-eye "))]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Filtros")])],1)]}}],null,false,4287590687)},[_c('span',[_vm._v(_vm._s(_vm.open?"Ocultar":"Mostrar")+" Filtro")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pt-5 pb-5",attrs:{"small":"","loading":_vm.refreshButtonState,"disabled":_vm.refreshButtonState},on:{"click":_vm.ActionTableRefresh}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-refresh ")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Refrescar")])],1)]}}],null,false,1574644223)},[_c('span',[_vm._v("Refrescar tabla")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pt-5 pb-5",attrs:{"value":"right","small":""},on:{"click":_vm.ActionTableExportToExcel}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-export ")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Excel")])],1)]}}],null,false,907821025)},[_c('span',[_vm._v("Exportar a Excel")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1):_vm._e(),(_vm.loadTable)?_c('br'):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.showHeaders,"items":_vm.tDataBody,"search":_vm.search,"loading":_vm.loadTable,"loading-text":"Cargando... Porfavor espere","fixed-header":"","height":"60vh"},on:{"contextmenu:row":_vm.rightClickHandler},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getColorState(item.state),"text-color":"white","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("textEstado")(item.state))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("textEstadoDesc")(item.state)))])])]}},{key:"item.estado_final",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getColorStateFinFirma(item.estado_final),"text-color":"white","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("textEstadoFinFirma")(item.estado_final))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("textEstadoFinFirmaDesc")(item.estado_final)))])])]}},{key:"item.visitado",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}],attrs:{"color":item.visitado > 0 ? 'green' : 'gray',"text-color":"white","small":""}},'v-chip',attrs,false),on),[(item.visitado > 0)?_c('v-icon',[_vm._v("mdi-eye-check-outline")]):_c('v-icon',[_vm._v("mdi-eye-settings-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Se visitó el enlace "+_vm._s(item.visitado)+" veces")])])]}},{key:"item.incidencias",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}],attrs:{"color":item.incidencias > 0 ? 'red' : 'blue',"text-color":"white","small":""}},'v-chip',attrs,false),on),[(item.incidencias > 0)?_c('v-icon',[_vm._v("mdi-emoticon-sad-outline ")]):_c('v-icon',[_vm._v("mdi-emoticon-outline")]),_vm._v("("+_vm._s(item.incidencias)+") ")],1)]}}],null,true)},[_c('span',[_vm._v(" Ocurrieron "+_vm._s(item.incidencias)+" incidencias")])])]}}])})],1),_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":"","transition":"scroll-y-transition"},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"600","tile":""}},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Menu")]),_c('v-list-item-group',{attrs:{"color":"primary"}},[_vm._l((_vm.items),function(item,i){return [(!item.divider)?_c('v-list-item',{key:i,on:{"click":function($event){return _vm.menuEventClick(item.title, item.action)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1):_c('v-divider',{key:i})]})],2)],1)],1)],1),_c('vmodal',{attrs:{"title":_vm.modalSetting.title,"openmodal":_vm.modalSetting.show,"json":_vm.modalSetting.data},on:{"closeEvent":_vm.changeOpenModal}}),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }