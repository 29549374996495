exports.install = function(Vue) {
    Vue.prototype.text1 = function() { // Función global 1
        alert('Ejecución exitosa 1');
    };
    Vue.prototype.text2 = function() { // Función global 2
        alert('Ejecución exitosa 2');
    };

    Vue.prototype.successAlert = function($titulo = "Alerta", $txt = false, $timer = false, $showbutton = true, $out = false) {
        this.swalalert("success", $titulo, $txt, $timer, $showbutton, $out);
    };
    Vue.prototype.warningAlert = function($titulo = "warning", $txt = false, $timer = false, $showbutton = true, $out = false) {
        this.swalalert("warning", $titulo, $txt, $timer, $showbutton, $out);
    };
    Vue.prototype.questionAlert = function($titulo = "question", $txt = false, $timer = false, $showbutton = true, $out = false) {
        this.swalalert("info", $titulo, $txt, $timer, $showbutton, $out);
    };
    Vue.prototype.errorAlert = function($titulo = "error", $txt = false, $timer = false, $showbutton = true, $out = false) {
        this.swalalert("error", $titulo, $txt, $timer, $showbutton, $out);
    };


    Vue.prototype.swalalert = function($type, $title = false, $content = false, $timer = 3000, $showbutton = false, $out = false) {
        this.$swal.fire({
            position: 'top-center',
            icon: $type,
            title: $title,
            html: $content,
            showConfirmButton: $showbutton,
            timer: $timer,
            timerProgressBar: true,
            allowOutsideClick: $out,
        });
    }


    Vue.prototype.sendAlert = function($title = "", $text = false, $timer = false, $out = false) {
        this.$swal.fire({
            title: $title,
            html: $text,
            timer: $timer,
            timerProgressBar: true,
            allowOutsideClick: $out,
            didOpen: () => {
                this.$swal.showLoading();
            },
        });
    }
    Vue.prototype.toastAlert = function(icon = "success", title = "Mensaje", timer = 3000,position="top-end") {
        this.$swal.fire({
            icon: icon,
            title: title,
            toast: true,
            position: position,
            showConfirmButton: false,
            timer: timer,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
        })
    }
};