<template>
  <div>
    <div class="preloader">
      <div class="preloader__status">
        <div status class="preloader__status-text text-center logo-mobile" v-show="loadPreloader">
          <img class="h-10 mb-2" :src="logo" alt="Credicorp logo" id="logoPrealoader2"  />

          <span class="mt-5 fsize-custom"> {{ loaded }}%</span>
        </div>
        <div class="preloader__status-loader">
          <div loader :style="loadStyle" class="preloader__status-bar"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/js/bootstrap.min.js"></script>
<script>
import auth from "@/auth/logic/auth";
export default {
  props: ["logo"],
  data() {
    return {
      loadPreloader:false,
      loaded: 0,
      loading: null,
      loadStyle: {
        width: "0%",
      },
      loader: $("[loader]"),
      body: document.querySelector("body"),
    };
  },
  mounted() {
    const cover = document.querySelector("#logoPrealoader2");
    cover.onload = () => {
      this.loadPreloader = true;

       this.preloader = $(this.$el);
    this.removeScrolling();
    this.startLoading();
    };
   
  },
  watch: {
    loaded() {
      this.loadStyle.width = `${this.loaded}%`;
    },
  },
  methods: {
    async register() {
      /* auth.register(this.email, this.password).then(response => {
        console.log(response);
      }) */
      try {
        await auth.register(this.email, this.password);
        this.$router.push("/");
      } catch (error) {
        console.log(error);
      }
    },
    removeScrolling() {
      this.body.style.overflow = "hidden";
    },
    startLoading() {
      this.loading = setInterval(this.load, 20);
    },
    load() {
      this.loaded < 100 ? this.loaded++ : this.doneLoading();
    },
    doneLoading() {
      clearInterval(this.loading);
      this.updateStatus();
      this.loaded = "100";
    },
    updateStatus() {
      // this.statusElem.text("Lets get crazay !");
      this.loader.fadeOut();
      this.animatePreloader();
    },
    animatePreloader() {
      let app = this,
        height = this.preloader.height(),
        properties = {
          "margin-top": `-${height}`,
        },
        options = {
          duration: 1000,
          easing: "swing",
          complete() {
            app.removePreloader();
          },
        };
      this.preloader.delay(500).animate(properties, options);
    },
    removePreloader() {
      this.preloader.remove();
      this.body.removeAttribute("style");
      this.animateWebsite();
    },
    animateWebsite() {
      console.log("lets get pretty");
    },
  },
};
</script>


<style>
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrQ.ttf)
    format("truetype");
}
.preloader {
  width: 100%;
  height: 100vh;
  background: #54565a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Raleway", sans-serif;
  position: relative;
}
.preloader__status {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.preloader__status-text {
  font-size: 40px;
  font-family: "Raleway", sans-serif;
  margin-bottom: 20px;
}
.preloader__status-loader {
  width: 100%;
  height: 3px;
}
.preloader__status-bar {
  background: white;
  height: 100%;
}
.frontpage {
  height: 100vh;
  background: #34495e;
}
.frontpage__title {
  color: white;
}
</style>