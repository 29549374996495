<template>
  <div>
    <div class="text-center mb-4">
      <span class="text-h6 text--secondary">
        <img
          :src="logo"
          alt="Vuetify Logo"
           draggable=false class="mr-1 w-80"
          style="vertical-align: middle;" 
        >
      </span>
    </div>
    <div class="transition-wrapper">
      <transition :name="transitionName">
        <router-view @next="$emit('next', $event)" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    transitionName: 'slide-left',
    logo: require("@/assets/img/logo.png"),
  }),

  watch: {
    $route (to) {
      this.transitionName = (to.name === 'password') ? 'slide-left' : 'slide-right'
    }
  }
}
</script>

<style lang="scss">
.transition-wrapper{
  position: relative;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-left-enter-active, .slide-left-leave-active, .slide-right-enter-active, .slide-right-leave-active {
  transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  width: 100%;
}

.slide-left-enter, .slide-right-leave-to {
  transform: translateX(500px);
}

.slide-left-leave-to, .slide-right-enter {
  transform: translateX(-500px);
}
</style>
