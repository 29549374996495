import router from './../../router';

export default {
  
  install(Vue) {
    const api = Vue.prototype.$apis;
    Vue.prototype.apiLoadBiometric = function () {
      return new Promise(function (resolve, reject) {
        Vue.axios
          .post(api._LoadPageView, getRoute())
          .then((r) => resolve(r))
          .catch((e) => reject(e));
      });
    };
    Vue.prototype.apiVerifyToken = function (obj = false) {
      return new Promise(function (resolve, reject) {
        Vue.axios
          .post(api._LoadVerifyToken, (obj != false)?obj: getRoute())
          .then((r) => resolve(r))
          .catch((e) => reject(e));
      });
    };

    Vue.prototype.apiLoadSigner = function (obj = false) {
      return new Promise(function (resolve, reject) {
        Vue.axios
          .post(api._LoadPageSigner, (obj != false)?obj: getRoute())
          .then((r) => resolve(r))
          .catch((e) => reject(e));
      });
    };

    Vue.prototype.apiSendOtp = function (article) {
      return new Promise(function (resolve, reject) {
        Vue.axios
          .post(api._SendOtp, article)
          .then((r) => resolve(r))
          .catch((e) => reject(e));
      });
    };
    const getRoute = function(){
      let idparam = router.history.current.params.token;
      return { token:idparam };
    }
  },
};
