<template>
    <a 
     @click="$emit('item-documento', todo.id)"
      data-document="../../documentos/userid/flujo/91234567_Perfil.pdf"
      class="list-group-item hover:bg-gray-100 hover:text-gray-800 
                transition
                duration-100
                cursor-pointer " :class="{active: todo.active}"
      ><i class="fa fa-file-pdf"></i> {{todo.title}}</a
    >

    
  
</template>

<script>
export default {
  name: "TodoItem",
  props: ["todo"],
};
</script>

<style scoped>

</style>