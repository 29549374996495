<template>
  <div>
    <json-viewer
      :value="jsonData"
      :expand-depth="5"
      :copyable="{copyText: 'Copiar', copiedText: 'Copiando...', timeout: 2000}"
      sort
    ></json-viewer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["json", "idtransaccion"],
  data() {
    return {
      jsonData: {
        message: "Cargando...",
      },
    };
  },
  async mounted() {
     this.jsonData = await this.consultar();
  },
  methods: {
    async consultar() {
      const id = 47;

      const response = await axios
        .get(this.$apis.consola.logBiometric + "/" + id)
        .catch((error) => error.response);
      if (response.status != 200) {
        if (response.status == 500)
          return this.stopSecuence("Error en el servidor");
        else if (response.status == 404)
          return this.stopSecuence(
            !response.data.message
              ? "No hay conexión con el servicio"
              : response.data.message
          );
        else return this.stopSecuence(response.data.message);
      }
  
      console.log(response);

      return response.data.data;
    },
  },
};
</script>