<template>
    <section >
       
         <button
         @click="$emit('firmar-documento', true)"
        class="
          btn-salmon
          hover:btn-salmon
          text-gray-800
          border border-gray-400
           py-2
          px-4
          rounded
        "
        :class="clases"
        v-html="html"
      >
      </button>
    </section>
</template>

<script>

    export default {
        name: 'Button-Firmar',
        props: ['html', "clases"],
    }
</script>

<style></style>