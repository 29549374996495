<template>
  <div>
    <div class="text-center w-100">
      <h1 class="text-h5 mb-2">
        {{ $vuetify.lang.t("$vuetify.auth.sign-in-password.title") }}
      </h1>
      <v-chip
        class="mb-10"
        outlined
        link
        @click="retro"
      >
        <v-avatar left>
          <v-icon color="secondary"> mdi-account-circle </v-icon>
        </v-avatar>
        {{ this.$store.state.identifier }}
        <v-avatar right>
          <v-icon color="secondary"> mdi-chevron-down </v-icon>
        </v-avatar>
      </v-chip>

      <v-form @submit.prevent="login">
        <v-text-field
          ref="input"
          v-model="password"
          class="mb-10"
          :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          :label="
            $vuetify.lang.t('$vuetify.auth.sign-in-password.enter-password')
          "
          name="password"
          :type="show ? 'input' : 'password'"
          hide-details="auto"
          outlined
          @click:append="show = !show"
        />
      </v-form>

      <div class="d-flex justify-space-between">
        <!-- <v-btn
          class="text-none letter-spacing-0 font-weight-bold"
          style="margin-left: -16px;"
          color="primary"
          text
          @click="wip"
        >
          {{ $vuetify.lang.t('$vuetify.auth.sign-in-password.forgot-password') }}
        </v-btn> -->
        <v-btn
          block
          class="text-none"
          style="min-width: 88px"
          color="primary"
          depressed
          @click="login"
        >
          {{ $vuetify.lang.t("$vuetify.auth.sign-in-password.next") }}
        </v-btn>
      </div>
      <br />
      <div v-if="messagelogin.length>0">
        <v-alert dense outlined type="error"> {{messagelogin}} </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { wip } from "@/helpers.js";

export default {
  data: () => ({
    error: null,
    show: false,
    logeando: false,
  }),
  computed: {
    password: {
      get() {
        return this.$store.state.password;
      },
      set(value) {
        this.$store.commit("updatePassword", value);
      },
    },
    messagelogin: {
      get() {
        return this.$store.state.messagelogin;
      },
    },
  },
  created() {
    if (this.$store.state.identifier == "") {
      this.$router.push({ name: "signin" });
    }
  },
  methods: {
    wip,
    login() {
      this.logeando = !this.logeando;
      /*  setTimeout(() => {
        this.logeando = true;
      }) */
      if (!this.password) {
        this.error = "Introduzca su contraseña";
        this.$refs.input.focus();
        return;
      }

      this.$emit("next", {
        action: "password",
        value: this.password,
      });
    },
    retro(){
      this.$router.push({ name: 'signin' })
       this.$store.commit("updateMessageLogin", '');
    }
  },
};
</script>
