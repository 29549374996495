import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './core/plugins/vuex'
import vuetify from './core/plugins/vuetify'
import JsonExcel from "vue-json-excel";
import VueSweetalert2 from 'vue-sweetalert2';
import VuePdfApp from "vue-pdf-app";
import VueOtp2 from 'vue-otp-2';
import OtpInput from "@bachdgvn/vue-otp-input";
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VCalendar from 'v-calendar';
import VueSkeletonLoader from 'skeleton-loader-vue';
import Skeleton from 'vue-loading-skeleton';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faCoffee, faStroopwafel, faDragon, faSpinner, faAlignLeft, fas } from '@fortawesome/free-solid-svg-icons'
import JsonViewer from 'vue-json-viewer'

// import VAnimateCss from 'v-animate-css';
/* import { domain, clientId, audience } from '../auth_config.json';
import { Auth0Plugin } from '@/auth/auth0-plugin'; */


import "@/assets/css/tailwind.css";
import "@/assets/css/custom.css";
import "@/assets/css/limpiar-tw.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import "@/assets/libs/swalalert/sweetalert2-custom.scss";
import "@/assets/otp.css";
import "@/assets/libs/font-awesome/css/font-awesome.min.css";
import "@/assets/libs/fontello/css/fontello.css";
import 'animate.css';
import 'skeleton-elements/css/skeleton-elements.css';
//import global_ from './components/Global.vue'
import base from './assets/libs/swalalert/index.ts';
import "@/assets/libs/font-awesome.js"

window.$ = window.jQuery = require('jquery')

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
// Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('#token').getAttribute('value');


Vue.config.productionTip = false
    //Vue.prototype.GLOBAL = global_;
Vue.prototype.$appName = 'Credicorp';

library.add(faUserSecret, faCoffee, faStroopwafel, faDragon, faSpinner, faAlignLeft, fas);

const _apifoldername = "api/public";
var _apisApp = {
    "sessionliveness": `/V1/${_apifoldername}/credicorp-app/session/liveness`,
    "sessionautocapture": `/V1/${_apifoldername}/credicorp-app/session/autocapture`,
    "verify": `/V1/${_apifoldername}/credicorp-app/verify`,
    "login": `/V1/${_apifoldername}/credicorp-app/session`,
    "_LoadVerifyToken": `/V1/${_apifoldername}/credicorp-app/validate-link`,
    "_LoadPageSigner": `/V1/${_apifoldername}/credicorp-app/get-contracts`,
    "_SendOtp": `"/V1/${_apifoldername}/credicorp-app/send-otp`,
    "_SignContract": `/V1/${_apifoldername}/credicorp-app/sign-contract`,
    "showPdf": `/V1/${_apifoldername}/show-pdf`,
    "suscription": `/V1/${_apifoldername}/subscription`,
    "accesstoken": `/V1/${_apifoldername}/cc/access_token`,
    "listado": `/V1/${_apifoldername}/consola/lista`,
    "consola": {
        "logBiometric": `/V1/${_apifoldername}/free/log-biometric`,
        "showDataFinFirma": `/V1/${_apifoldername}/free/show-data-send-finfirma`,
    }
}
if (process.env.NODE_ENV === 'production') {
    _apisApp = {
        "sessionliveness": `/${_apifoldername}/credicorp-app/session/liveness`,
        "sessionautocapture": `/${_apifoldername}/credicorp-app/session/autocapture`,
        "verify": `/${_apifoldername}/credicorp-app/verify`,
        "login": `/${_apifoldername}/credicorp-app/session`,
        "_LoadVerifyToken": `/${_apifoldername}/credicorp-app/validate-link`,
        "_LoadPageSigner": `/${_apifoldername}/credicorp-app/get-contracts`,
        "_SendOtp": `/${_apifoldername}/credicorp-app/send-otp`,
        "_SignContract": `/${_apifoldername}/credicorp-app/sign-contract`,
        "showPdf": `/${_apifoldername}/show-pdf`,
        "suscription": `/${_apifoldername}/subscription`,
        "accesstoken": `/${_apifoldername}/cc/access_token`,
        "listado": `/${_apifoldername}/consola/lista`,
        "consola": {
            "logBiometric": `/${_apifoldername}/free/log-biometric`,
            "showDataFinFirma": `/${_apifoldername}/free/show-data-send-finfirma`,
        }
    }
}



Vue.prototype.$findJsonObject = function(data, alias, valor) {
    for (var i = 0; i < data.length; i++) {
        var slug = eval(`data[i].${alias}`);
        if (slug == valor) return data[i];
    }
}


Vue.prototype.$apis = _apisApp;



Vue.prototype.changeData = function() { // changeData es el nombre de la función
    alert('Ejecución exitosa');
}


const options = {
    confirmButtonColor: '#ea6752',
    cancelButtonColor: '#ff7674',
};
Vue.use(VueCompositionAPI)
Vue.use(base);
// Vue.use(VAnimateCss);
Vue.use(VueSweetalert2, options);
Vue.use(VueAxios, axios);
Vue.use(Skeleton);
Vue.use(VueOtp2);
Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
Vue.use(JsonViewer)

Vue.component("v-otp-input", OtpInput);
Vue.component("vue-pdf-app", VuePdfApp);
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component("downloadExcel", JsonExcel);
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)
import moment from 'moment'

Vue.prototype.moment = moment;
/* Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
        console.log("Auth0PluginXXXXXXX")
        router.push(
            appState && appState.targetUrl ?
            appState.targetUrl :
            window.location.pathname,
        );
    },
}); */
import servicios from "./core/api/all-services.ts"
Vue.use(servicios);

import '@/styles/styles.scss'
new Vue({
    router,
    store,
    vuetify,
    data() {
        return {
            info: null
        }
    },
    filters: {
        currencydecimal(value) {
            return value.toFixed(2)
        }
    },
    render: h => h(App)
}).$mount('#app')