<template>
   <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      logo: true
    };
  },
};
</script>

<style>
</style>
