<template>
  <div
    class="items-center justify-center"
    :class="{ 'flex h-screen-nav': paneles }"
  >
    <div id="liveness"></div>
    <div id="container"></div>
    <div id="barcode-picker"></div>
    <div class="text-white rounded-lg p-10 text-center" v-if="paneles">
      <h1
        class="
          text-3xl
          font-normal
          leading-normal
          mt-0
          mb-2
          tc-salmon
          font-bold
        "
      >
        Importante
      </h1>

      <div id="panel-cam">
        <div class="flex flex-col text-left">
          <div class="py-2 tc-gris-ladrillo tc-gris-oscuro">
            Para tomarse la foto, asegúrese de retirarse la mascarilla, lentes o
            gorra.
          </div>
          <div class="py-2 tc-gris-ladrillo tc-gris-oscuro">
            Levante el teléfono a la altura de sus ojos y enmarque su rostro en
            el recuadro.
          </div>
          <div class="py-2 tc-gris-ladrillo tc-gris-oscuro">
            Acepte el uso de la cámara.
          </div>
        </div>
        <div class="w-full rounded">
          <img
            :src="gifimg"
            alt=""
            id="gifImg"
            style="display: none"
            v-if="!loadImagenGif"
          />
          <div
            class="
              lg:w-48
              m-auto
              img-personal
              bg-contain bg-no-repeat bg-center
            "
            :style="loadImagenGif ? `background-image: url('${gifimg}')` : ''"
          >
            <vue-skeleton-loader
              type="rect"
              :width="195"
              :height="195"
              animation="wave"
              class="lg:w-48 m-auto img-personal"
              style="z-index: -1"
            />
          </div>
        </div>

        <btnCamara
          v-on:init-camara="initFacial"
          :label="'Iniciar'"
          :loadBtnCamera="buttonInit"
        />
      </div>

      <div v-if="loderSpinnerCam" id="loader-cam" class="animated fadeInUp">
        <div class="loader-cam"><i class="icon-camera"></i></div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/4.13.0/bodymovin.min.js" defer></script>
<script>
import btnCamara from "./ButtonCamara.vue";
import axios from "axios";
import router from "@/router";
export default {
  name: "Camara",
  data() {
    return {
      loadImagenGif: false,
      query: "",
      gifimg: require("@/assets/img/persona.gif"),
      firmante: " objFirmante[0]",
      buttonInit: false,
      paneles: true,
      loderSpinnerCam: false,
      form: {
        token: null,
        tokenLiveness: null,
        files: [
          { side: "selfie", token: null, bs64: null },
          { side: "front", token: null, bs64: null },
          { side: "back", token: null, bs64: null },
        ],
        locale: "es",
        document_type: null,
      },
    };
  },
  components: {
    btnCamara,
  },
  props: ["swapComponent", "tipoDocumento"],
  created() {
    console.log("test");
    console.log(this.tipoDocumento);
    if (this.tipoDocumento.length < 0)
      return this.swapComponent("SeleccioneDocumento");

    this.form.document_type = this.tipoDocumento;
    this.form.token = this.$route.params.token;
  },
  methods: {
    async initFacial() {
      //  await new Promise(resolve => setTimeout(resolve, 20000));
      let $self = this;
      if (this.buttonInit == true) return false;
      this.buttonInit = true;
      let getIdSession = null;
      if (this.form.tokenLiveness == null) {
        let rs = await this.getIdSession();
        getIdSession = await this.errorGetIdSession(rs);
        if (!getIdSession) return false;
        this.form.tokenLiveness = getIdSession;
      }
      getIdSession = this.form.tokenLiveness;
      //MODO CELULAR
      $self.hidePanel();

      if (this.form.files[0].token == null) {
        //LIVENNES
        let liveness = await $self.readyLiveness(getIdSession).then();
        if (!liveness) return false;
        else this.successAlert("Paso 1: Completado", "", 3000, false);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }

      if (this.form.files[1].token == null) {
        //AUTOCAPTURE FRONT
        let captureFront = await $self.readyAutoCapture(getIdSession, "front");
        if (!captureFront) return false;
        // else this.successAlert("Paso 2: Completado", "", 3000, false);
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      if (this.form.files[2].token == null) {
        //AUTOCAPTURE BACK
        let captureBack = await $self.readyAutoCapture(getIdSession, "back");
        if (!captureBack) return false;
        else this.successAlert("Paso 2: Completado", "", 3000, false);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
      document.querySelector("body").style.background = "transparent";
      this.sendAlert(
        "Por favor, espere",
        "un momento mientras validamos sus datos"
      );

      await new Promise((resolve) => setTimeout(resolve, 3000));

      await axios
        .post($self.$apis.verify, this.form)
        .then((r) => {
          let rs = r.data;
          if (rs.error) {
            if(rs.refresh) this.resetearBiometric(rs.refresh);
            
            $self.showPanel();
            return $self.errorAlert(
              "Lo sentimos",
              `No hemos podido validar su identidad. <br><br> ${rs.message}`
            );
          }
          this.$swal.fire({
            position: "top-center",
            icon: "success",
            title: rs.data.titulo,
            html: rs.data.message,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            willClose: () => {
              $self.$emit("escuchar-hijo", rs);
            },
          });
        })
        .catch(() => {
          $self.showPanel(
            "Ocurri&oacute; un error, int&eacute;ntelo m&aacute;s tarde",
            "error"
          );
        });
    },
    readyLiveness: function (id) {
      let $self = this;
      return new Promise((resolve, reject) => {
        TOCliveness("liveness", {
          locale: $self.form.locale,
          session_id: id,
          alt_server: "https://prod-liveness.tocws.com",
          url_lbliv: "https://prod-api.7oc.cl/liveness/image-saver/v1",
          callback: function (token, foto) {
            $self.form.files[0] = {
              side: "selfie",
              token: token,
              bs64: encodeURIComponent(foto),
            };

            resolve(true);
          },
          failure: function (error) {
            $self.showPanel($self.notifyLive(error), "error");
            resolve(false);
          },
        });
      });
    },
    readyAutoCapture: function (id, side) {
      let $self = this;
      return new Promise((resolve, reject) => {
        TOCautocapture("container", {
          locale: $self.form.locale,
          session_id: id,
          document_type: $self.tipoDocumento,
          document_side: side,
          alt_server: "https://prod-capture.tocws.com",
          url_lbac: "https://prod-api.7oc.cl/auto-capture/data/v2",
          callback: function (ctoken, image) {
            let step = side == "front" ? 1 : 2;
            console.log(step + ": " + ctoken);
            $self.form.files[step] = {
              side: side,
              token: ctoken,
              bs64: encodeURIComponent(image),
            };

            resolve(true);
          },
          failure: function (error) {
            $self.showPanel($self.notifyAutocapt(error), "error");
            resolve(false);
          },
        });
      });
    },
    getIdSession: function () {
      let $self = this;
      return new Promise((resolve, reject) => {
        fetch(this.$apis.sessionliveness, {
          method: "POST",
        })
          .then((r) => r.json())
          .then((rs) => resolve(rs))
          .catch((e) => {
            $self.$swal.fire(
              "Alerta",
              "Ocurrió un error inesperado en los servicios",
              "error"
            );
            $self.buttonInit = false;
            reject(e);
          });
      });
    },
    errorGetIdSession: function (rs) {
      if (rs.error) {
        this.$swal.fire("Alerta", rs.message, "error");
        return false;
      }
      if (rs.data.status != 200) {
        this.$swal.fire("Alerta", rs.data.message, "error");
        return false;
      }
      return rs.data.session_id;
    },
    initAutocapture: function () {
      let $self = this;
      this.getIdSession().then((rs) => {
        if (rs.error) return $self.$swal.fire("Alerta", rs.message, "error");
        if (rs.data.status == 200) {
          $self.readyAutoCapture(rs.data.session_id);
          $self.hidePanel();
        } else $self.$swal.fire("Alerta", rs.data.message, "error");
      });
    },
    hidePanel() {
      let $self = this;
      document.querySelector("nav.nav-custom").style.display = "none";
      document.querySelector("body").style.background = "#000";
      $self.paneles = false;
    },
    showPanel(message = "", tipo = "error") {
      this.loderSpinnerCam = false;
      if (message.length > 0) this.$swal.fire("Alerta", message, tipo);
      this.buttonInit = false;
      this.paneles = true;
      document.querySelector("nav.nav-custom").style.display = "block";
      document.querySelector("body").style.background = "transparent";
    },
    solicitarPin(formData) {
      $selfModal = this;
      Swal.queue([
        {
          title: "Generando Pin",
          showConfirmButton: false,
          text: "Espere un momento porfavor",
          timerProgressBar: true,
          allowOutsideClick: false,
          willOpen: () => {
            Swal.showLoading();
            (async () => {
              let data = $selfModal.firmante.otpDate;
              let otpDate = new FormData();
              for (var key in data) otpDate.append(key, data[key]);
              await fetch(_apiSolicitarPin, {
                method: "POST",
                body: otpDate,
              })
                .then((rs) => rs.json())
                .then((rs) => {
                  $selfModal
                    .modalInsertPin(rs, formData)
                    .then((r) => {
                      if (r.error) errorAlert(rs.msj);
                      else successMessajeSign();
                    })
                    .catch(() => {
                      errorAlert("Algo sali&oacute; mal");
                    });
                })
                .catch((e) =>
                  errorAlert(
                    "Error al solicitar el PIN de verificaci&oacute;n",
                    "Vuelva a realizar el proceso para solicitar un nuevo PIN",
                    8000
                  )
                );
              $selfModal.restoreButtons();
            })();
          },
        },
      ]);
    },
    modalInsertPin(rs, data) {
      console.log(2);
      $html = `<form role="form"  autocomplete="off" class="text-center">
            <div class="form-group" >
                <img src="../img/empresa/20603038160_C.png">
                <p class="text-center fs-6 text-muted">Se ha enviado un mensaje al correo: <strong>${rs.em}</strong><br>
                Se ha enviado un mensaje al celular: <strong>${rs.cl}</strong><br>
              Introduce el código que has recibido para validar legalmente tu firma</p>
            </div>
            <div class="form-group" >
                <input name="tk" id="tk" type="hidden" value="${rs.tk}">
                <input name="id" id="id" type="hidden" value="${rs.id}">
                <p class="text-center fs-6 fw-bold text-muted">Al ingresar el Pin, que se le envio, dará por aceptado y firmado los documentos seleccionados.</p>
            </div>
        </form>`;
      return new Promise(function (resolve, reject) {
        Swal.fire({
          title: false,
          html: $html,
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          inputPlaceholder: "Ingrese el PIN",
          showCancelButton: true,
          confirmButtonText: "Firmar",
          cancelButtonText: "Cancelar",
          showLoaderOnConfirm: true,
          preConfirm: (pin) => {
            if (pin == "")
              return Swal.showValidationMessage(
                `Mensaje: El campo no puede estar vac&iacute;o`
              );

            data.append("tk", rs.tk);
            data.append("id", rs.id);
            data.append("codigo", pin);
            return fetch(_apiVerifyOtp, {
              method: "POST",
              body: data,
            })
              .then((rs) => {
                if (!rs.ok) throw new Error(rs.statusText);
                return rs.json();
              })
              .then((r) => {
                if (r.error) return Swal.showValidationMessage(r.msj);
                resolve(r);
              })
              .catch((error) => {
                Swal.showValidationMessage(
                  "No se logr&oacute; firmar, error al ingresar el PIN de seguridad"
                );
                reject();
              });
          },
          allowOutsideClick: false,
        }).then((response) => {
          // if (response.isConfirmed) callback(response.value);
          if (response.isConfirmed) reject();
        });
      });
    },
    notifyLive(error, alert = true) {
      var $mensaje = "Error al abrir la c&aacute;mara";
      if (error == 402)
        $mensaje = "Se super&oacute; el tiempo del proceso de liveness";
      else if (error == 403) $mensaje = "Cancelado por el usuario";
      else if (error == 404) $mensaje = "No hay conexi&oacute;n a internet";
      else if (error == 405) $mensaje = "Session-id inv&aacute;lido";
      else if (error == 406)
        $mensaje = "No se ha podido resolver el endpoint entregado";
      else if (error == 411) $mensaje = "Falta session id";
      else if (error == 412) $mensaje = "Falta delegado";
      else if (error == 413) $mensaje = "Falta controller inicial";
      else if (error == 430)
        $mensaje =
          "Im&aacute;gen del rostro no pasa validaciones m&iacute;nimas";
      else if (error == 450) $mensaje = "Error no controlado";
      if (alert) this.$swal.fire("Alerta", $mensaje, "error");
      else return $mensaje;
    },
    notifyAutocapt(error, alert = true) {
      var $mensaje = "Error: al abrir la c&aacute;mara";
      if (error == 402)
        $mensaje = "Se super&oacute; el tiempo del proceso de liveness";
      else if (error == 401) $mensaje = "Env&iacute;o de datos fallido";
      else if (error == 402) $mensaje = "Tiempo l&iacute;mite excedido";
      else if (error == 403) $mensaje = "Cancelado por el usuario";
      else if (error == 404) $mensaje = "No hay conexi&oacute;n a internet";
      else if (error == 405) $mensaje = "Sesi&oacute;n expirada";
      else if (error == 406) $mensaje = "No hay conexi&oacute;n con servidor";
      else if (error == 411) $mensaje = "Falta session id";
      else if (error == 412) $mensaje = "Falta el tipo de documento";
      else if (error == 413) $mensaje = "Falta el lado a capturar";
      else if (error == 414) $mensaje = "Falta iOS delegate";
      else if (error == 415) $mensaje = "Falta iOS controller";
      else if (error == 421) $mensaje = "session id no v&aacute;lido";
      else if (error == 422) $mensaje = "Tipo de documento no soportado";
      else if (error == 430) $mensaje = "La c&eacute;dula no pudo ser validada";
      else if (error == 431) $mensaje = "session id ya utilizado";
      else if (error == 450) $mensaje = "Error de plugin web";
      else if (error == 500) $mensaje = "Error de camara";
      if (alert) this.$swal.fire("Alerta", $mensaje, "error");
      else return $mensaje;
    },
    sendDataForm: function () {
      /*  
            $self.loderSpinnerCam = true;
              axios
                .post($self.$apis.verify, article)
                .then(rs => {
                  if (rs.data.error) return $self.showPanel(rs.data.message, "error");
                  $self.successAlert("Paso 1: Completado", "", 3000, true);
                  $self.initAutocapture();
                })
                .catch(() => {
                  $self.loderSpinnerCam = false;
                  $self.showPanel(
                    "Ocurri&oacute; un error, reintente otra vez",
                    "error"
                  );
                });
          */
    },
    resetearBiometric: function (v = "all") {
      if (v == "all") {
        this.form.files = [
          { side: "selfie", token: null, bs64: null },
          { side: "front", token: null, bs64: null },
          { side: "back", token: null, bs64: null },
        ];
      } else {
        if (v == "selfie")
          this.form.files[0] = { side: "selfie", token: null, bs64: null };
        else if (v == "front")
          this.form.files[1] = { side: "", token: null, bs64: null };
        else this.form.files[2] = { side: "back", token: null, bs64: null };
      }
    },
  },
  mounted() {
    const cover = document.querySelector("#gifImg");
    cover.onload = () => {
      this.loadImagenGif = true;
    };
  },
};
</script>

<style scoped>
.img-personal {
  max-height: 200px;
}
.bgblackcustom {
  background: #000;
}
.loader {
  z-index: -1;
}
</style>