<template>
   <div class="relative rounded-xl overflow-auto p-8">
        <div class="flex items-center justify-center">
          <span class="relative inline-flex">
            <button
            @click="swapComponent(nextNameComponent)"
              type="button"
              class="
                inline-flex
                text-white
                py-2
                px-4
                rounded
                btn-salmon
                hover:btn-salmon
              "
            >
              {{label}}
            </button>
            <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
              <span
                class="
                  animate-ping
                  absolute
                  inline-flex
                  h-full
                  w-full
                  rounded-full
                  bg-salmon2-400
                  opacity-75
                "
              ></span>
              <span
                class="relative inline-flex rounded-full h-3 w-3 bg-salmon2-500"
              ></span>
            </span>
          </span>
        </div>
      </div>
</template>

<script>
export default {
  name: "ButtonNext",
  props: ["label","swapComponent","nextNameComponent"],
};
</script>

<style scoped>

</style>