export default {
    state: {
        identifier: '',
        password: '',
        messagelogin: '',
        loading: false,
        disabled: false
    },

    mutations: {
        updateIdentifier(state, identifier) {
            state.identifier = identifier
        },

        updatePassword(state, password) {
            state.password = password
        },
        updateMessageLogin(state, messagelogin) {
            state.messagelogin = messagelogin
        },
        setLoading(state, loading) {
            state.loading = loading
        },

        setDisabled(state, disabled) {
            state.disabled = disabled
        }
    }
}