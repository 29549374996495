<template>
  <div>
    <nav class="nav-custom">
      <div class=" mx-auto md:px-4">
        <div class="flex justify-between">
          <div class="flex space-x-7">
            <!-- Website Logo -->
            <div>
              <div class="flex items-center py-4 px-2">
                <img :src="logo" alt="Logo" class="h-6 mr-2 ml-2" draggable="false" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["logo"],
  methods: {},
};
</script>

<style scope>
</style>
    
