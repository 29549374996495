<template>
  <div class="flex items-center justify-center h-screen-nav">
    <div class=" text-white rounded-lg p-10">
      <h1
        class="
          text-3xl
          font-normal
          leading-normal
          mt-0
          mb-2
          tc-salmon
          font-bold
          text-center
        "
      >
        Recomendaciones
      </h1>
      <div class="flex flex-col divide-y  ">
        <div class="py-3 tc-gris-ladrillo tc-gris-oscuro ">
          <i class="icon-mobile-2 icon-credicorp"></i> Realice el proceso desde su Smartphone.
        </div>
        <div class="py-3 tc-gris-ladrillo tc-gris-oscuro">
          <i class="icon-network-1 icon-credicorp"></i> Si es <strong>Android</strong> utilice Chrome, Firefox u Opera. Si es
          <strong>iPhone</strong> use Safari.
        </div>
        <div class="py-3 tc-gris-ladrillo tc-gris-oscuro ">
          <i class="icon-camera-3 icon-credicorp"></i> La captura del selfie y DNI deben hacerse en un ambiente bien
          iluminado.
        </div>
        <div class="py-3 tc-gris-ladrillo tc-gris-oscuro"><i class="icon-vcard icon-credicorp"></i> Tenga su DNI a la mano.</div>
        <div class="py-3 tc-gris-ladrillo tc-gris-oscuro ">
         <i class="icon-signal-2 icon-credicorp"></i> Asegúrese de tener una conexión a internet estable y sin
          restricciones.
        </div>
      </div>

      <ButtonNext
        :label="'Continuar'"
         :swap-component="swapComponent"
        :nextNameComponent ="'SeleccioneDocumento'"
      />
    </div>
  </div>
</template>

<script>
import ButtonNext from "./ButtonNext.vue";
export default {
  name: "Recomendaciones",
  props: ["swapComponent"],
  data() {
    return {
      query: "",
       configAnimation: {
        text1: {
          classes: "fadeInDown",
          delay: 1000,
        },
        text2: {
          classes: "fadeInDown",
          delay: 1500,
        },
        text3: {
          classes: "fadeInDown",
          delay: 2000,
        },
      },
    };
  },
  components: {
    ButtonNext,
  },
};
</script>

<style scoped>
#search-container {
  width: 600px;
  margin: 0 auto;
}
input {
  padding: 10px;
  width: 100%;
  outline: none;
}

.icon-credicorp{
  display:none;
}
</style>