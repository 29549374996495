<template>
  <div>
    <Verificando
      :texto="'Validando enlace'"
      v-if="!verficandoPage"
    ></Verificando>

   
  </div>
</template>

<script>
import {Verificando} from "@/core/components/layout/";
export default {
  name: "App",
  data() {
    return {
      logo: require("../../../assets/img/logo.png"),
      logoPrealoader: require("../../../assets/img/logo-blanco-preloader.svg"),
      currentComponent: "Pasosdelproceso",
      tipoDocumento: null,
      verficandoPage: true,
      datareader: null,
    };
  },
  components: {
    Verificando: Verificando,
  },
  async created() {
    let idparam = this.$route.params.token;
    if (idparam == "") this.$router.push("/notfount");
  },
};
</script>

<style>
.tc-salmon {
  color: #ea6752;
}

.btn-salmon {
  background: #ea6752;
  color: #fff;
}

.btn-salmon:hover {
  background: #e06451;
  color: #fff;
}

.btn-salmon-secondary {
  background: transparent;
  color: #ea6752;
}

.btn-salmon-secondary:hover {
  background: #e06451;
  color: #fff;
}

.tc-gris-oscuro {
  color: #54565b;
}

.tc-gris-claro {
  color: #8f9c9d;
}

.tc-azul {
  color: #26334a;
}

.tc-celest {
  color: #7aa2d6;
}

.tc-ladrillo {
  color: #402020;
}

.btn-gris-claro {
  background: #8f9c9d;
  color: #54565b;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-leave-active {
  transition: 0.3s;
}
.slide-enter-active {
  transition: 0.1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>
